<template>
    <div class="downloadBox" :class="isTc ? 'tc' : 'zh'">
        <div class="downloadCon" >
            <div class="downBtn">
                <img :src="imgQrcode" alt="">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            imgQrcode:'',
            isTc:false
        }
    },
    created() {
        // 下载二维码根据不同环境展示不同二维码
        // https://wap.hticm.com.hk/#/download 测试环境的下载中间地址
        // https://gjzbwap.uata.haomalljf.com/#/download 预生产环境的下载中间地址
        // https://gjzbwap.qasa.chtwm.com/#/download 生产环境的下载中间地址

        let hostStr = window.location.host
        if( hostStr == 'gjzbpc.qasa.chtwm.com') {
            // 测试环境
            this.imgQrcode = require('@/assets/img/qasadown.png');
        }else if( hostStr == 'gjzbpc.uata.haomalljf.com'){
            // 预生产环境
            this.imgQrcode = require('@/assets/img/uatadown.png');
        }else if( hostStr == 'www.hticm.com.hk'){
            // 生产环境
            this.imgQrcode = require('@/assets/img/onlinedown.png');
        }else{
            this.imgQrcode = require('@/assets/img/qasadown.png');
        }
        // 判断是繁体还是简体
        if(this.$store.state.language == 'zh'){
            this.isTc = false
        }else{
            this.isTc = true
        }
    },
    watch:{
        "$store.state.language":{
            handler:function(newVal,oldVal){
                if(newVal == 'tc'){
                    //繁体
                    this.isTc = true
                }else{
                    //简体
                    this.isTc = false
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.downloadBox {
    width: 100%;
    height: 630px;
    background: url('../../assets/img/downappbg.png') no-repeat center center;
    background-size: cover;
    .downloadCon{
        width: 1159px;
        height: 497px;
        margin: 85px auto 48px;
        position: relative;
        // background: url('../../assets/img/downtext.png') no-repeat center center;
        // background-size: cover;
    }
    .downBtn{
        width: 384px;
        height: 140px;
        // background:url('../../assets/img/downbox.png') no-repeat center center;
        background-size: 100% 100%;
        position: absolute;
        left: 236px;
        bottom: 4px;
        img{
            width: 96px;
            height: 96px;
            border-radius: 8px;
            position: absolute;
            top: 12px;
            left: 12px;
        }
    }
}
.downloadBox.zh{
    .downloadCon {
        background: url('../../assets/img/downtext.png') no-repeat center center;
        background-size: cover;
    }
    .downBtn{
        background:url('../../assets/img/downbox.png') no-repeat center center;
        background-size: 100% 100%;
    }
}
.downloadBox.tc{
    .downloadCon {
        background: url('../../assets/img/downtexttc.png') no-repeat center center;
        background-size: cover;
    }
    .downBtn{
        background:url('../../assets/img/downboxtc.png') no-repeat center center;
        background-size: 100% 100%;
    }
}
</style>